@portalnavbg : #b09367;
@portal_dropdown_color:#91754b;
//@portalnavbg : #000;
@sitenavbg :#23527c;
@sitenavbg :#0c419f;
@sitenavbg : darken(@portalnavbg, 20);
@sitenavbg :#4f2c27;
@default_bkg : "/f/background.jpg";
@navcolor: lighten(@sitenavbg, 5);
@colorbg: white;
@kurdgray: #ccc;
@media (min-width: 767px) {
    .navbar-nav .dropdown-menu .caret {
    transform: rotate(-90deg);
    }
}
body{
    //font-size:14px;
    a{
        color: lighten(@sitenavbg, 5);
        &:hover {
            color: lighten(@sitenavbg, 20);
            text-decoration:underline;

        }
    }

}
h1,h2,h3,h4,h5,h6{
    color:@sitenavbg;
}
h1{
    margin-top:0px;
    margin-bottom:1em;
    font-size:2em;
    font-family: "Times New Roman";
}
h3{
    &.author{
        margin-top:0px;
    }
}
#background{
    background-image:url(@default_bkg);
    background-attachment:fixed;
    background-repeat:no-repeat;
//    background-position:0 -40px;
    background-size:cover;
    content:"";
    opacity: 0.5;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    position: fixed;
    z-index:-1;
}
#maincont{
    background-image: url(@default_bkg);
    background-attachment: fixed;
    //background-position:0 -40px;
    background-size:cover;
    background-repeat:no-repeat;
}
.navbar {
    margin-bottom:0px;
    border-radius:0px;
}
#portalNav-row{
    .navbar-inverse{
        //font-size:0.7em*1.1;
        background-color:@portalnavbg;
        border-color:@portalnavbg;
        a{color:white;}
        ul.navbar-nav{
            a{padding:15px 10px;}
            li.open{
                >a{
                background-color:@portal_dropdown_color;
                }
            }
        }
        ul[class*=ul_level]{
            background-color:white;

            a{
            color:@portal_dropdown_color;
            padding:3px 20px !important;
            }
        }
        .navbar-collapse{
            border-color:@portal_dropdown_color;
        }
        .navbar-toggle{
            border-color:@portal_dropdown_color;
            &:hover{
                background-color:@portal_dropdown_color;
            }
        }
    }
    span.search{
        float:right;
        width:120px;
        padding:8px 0;
        margin-right:-15px;
        .ya-site-form__submit{
            display:none;
        }
        .ya-site-form__input-text{
            border-radius:2px;
            &::-webkit-input-placeholder {
                color: @portal_dropdown_color;
            }
            &:-moz-placeholder {
                /* FF 4-18 */
                color: @portal_dropdown_color;
            }
            &::-moz-placeholder {
                /* FF 19+ */
                color: @portal_dropdown_color;
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                color: @portal_dropdown_color;
            }
        }
    }
}
#siteNav-row{
    font-size:1.2em;
    .navbar-inverse{
        background-color:fadeout(@sitenavbg,20%);
        border-color:@sitenavbg;
        a{
color:white;}
        
        ul.navbar-nav{
            width:100%;
            li.lang{
                float:right;
                a{
                    width:30px;
                    text-align:center;
                    background-color:white;
                    border-radius:50%;
                    margin-top:10px;
                    padding:5px;
                    color:@sitenavbg;
                }
            }
            li.open{
                >a{
                background-color:lighten(@sitenavbg,10%);
                }
            }
        }
        ul[class*=ul_level]{
            background-color:white;
            a{color:@navcolor;}
        }
        .navbar-collapse{
            border-color:lighten(@sitenavbg,10%);
            border-top:none;
        }
        .navbar-toggle{
            border-color:lighten(@sitenavbg,10%);
            &:hover{
                background-color:lighten(@sitenavbg,10%);
            }
        }
    }
}
#site-title-row{
    h1{
    text-transform: uppercase;
    font-family: Times New Roman;
    text-shadow: 2px 2px 3px black;
    color:white;
    margin-top:20px;
    margin-bottom:20px;
    }
    p{
        color:white;
        text-shadow: 2px 2px 3px black;
    }
}

.content{
    opacity:0.92;
    padding-top:40px;
    background-color:white;
    min-height:60vh;
}
.navbar-brand{
    padding:2px 15px;
}
footer{
    padding-top:10px;
    padding-bottom:10px;
    background-color:fadeout(@sitenavbg, 20%);
    color:white;
    font-size:0.8em;
    a{
        color:white;
        &:hover{
            color:white;
        }
    }
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    .address, .author{
        text-align:right;
    }
}
.after-footer{
    position:relative;
    margin-top:-80px;
    height:15vh;
    background-image: url(@default_bkg);
    background-attachment: fixed;
    //background-position:0 -40px;
    background-repeat:no-repeat;
    background-size:cover;
    z-index:-1;

}
.sidenav, .text-left{
    padding-bottom:2em;
}
.numbers{
    span:first-child{
        margin-left:0.7em;
    }
}
.number-list{
    p.year{
        margin-bottom:0px;
        margin-top:10px;
        background-color:lighten(@sitenavbg, 40%);
        color:white;
        padding-left:0.7em;
    }
    .number{
        //background-color:@sitenavbg;
        display:inline-block;
        //a {color:white;}
        padding:0px 5px 0px 0px;
        //margin:2px;
    }
}
.article{
    
}
ul.lang-menu{
    li.current{
        a{
            padding-top:0px;
            padding-bottom:0px;

        }
        &:not(.open){
            a{
                color:white;
            }
        }
    }
}
.logo{
    padding-top:10px;
    padding-bottom:10px;
    padding-right:30px;
}
.redkolegia{
    td{padding:10px;}
    .photo{
        width:75px;
        height:75px;
        background-size:cover;
        border-radius:37.5px;
    }
    .name{
        font-weight:bold;
        span.surname{
            text-transform:uppercase;
        }
    }
}
.kinolenta{
    padding: 0.5em 0;
    box-shadow: 0px -1px 4px rgba(0,0,0,0.5);
    background-image: url('//video.ivran.ru/f/kinolenta.gif');
    background-repeat: repeat-y;
    background-position: top center;
    background-size:contain;
    img.thumb{
        display:block;
        border: 1px solid #b2a27e;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        margin: 0 auto 0.3em;
        width:70%;
    }
    div.video-title{
        margin: 0 auto 1em;
        width:70%;
        a{
            line-height:100%;
            display: inline-block;
        }
        
    }
}
.switch-lang{
    a{
        background-color:@sitenavbg;
        color:white;
        border:1px solid @sitenavbg;
        border-radius:5px;
        padding:0.2em;
        &:hover{
            color:white;
        }
    }
}
ul.breadcrumbs{
    padding:0;
    li {
        display:inline-block; 
    }
    li + li::before {
        content: "\00BB";
        padding-right:5px;
    }
}
ul.issue-number-list{
    padding:0;
    li{
        display:inline-block;
        padding:1px;
        a {
            padding:2px 6px;
            background-color:@sitenavbg;
            color: @colorbg;
        }
    }
}
table.article-annotation{
    border-collapse:separate;
    border-spacing: 0 1em;
    td.row-label{
        padding-right:38px;
        color:#999;
    }
    td.row-value{
        a.author-link{
            font-weight:bold;
        }
    }
}

ul.tag-list{
    &:extend(ul.issue-number-list all);
    li a {
        border-radius:3px;
        background-color:#3d80ba;
    }
}
.bookrow{
    padding-left:7px;
    padding-right:7px;
}
.bookcard{
    position:relative;
    z-index:50;
    padding-left:10px;
    padding-right:10px;
    padding-top:10px;
    padding-bottom:10px;
    .bookpic{
        width:100%;
        border:1px solid #555;
    }
    .bookbg-parent{
        padding:15px;
        background-color:lighten(@kurdgray, 20%);
        line-height:1;
    }
    .bookbg{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-color:#bbb;
        margin-bottom:10px;
    }
    .author{
        font-size:10pt;
        &.title-eng{
            margin-top:10px;
            margin-bottom:0;
        }
    
    }
    .title{
        font-size:10pt;
    }
}
.h-100 {
    height: 100% !important;
}
.magbanners{
   .bookpic{border:0;}
   .bookbg{
       background-color:transparent;
   }
}
.photo{
    width:64px;
    height: 64px;
    background-size: cover;
    border-radius: 32px;
    float: left;
    margin-right: 24px;
    margin-bottom:1em;
}
