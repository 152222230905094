@media (min-width: 767px) {
  .navbar-nav .dropdown-menu .caret {
    transform: rotate(-90deg);
  }
}
body a {
  color: #60362f;
}
body a:hover {
  color: #935249;
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4f2c27;
}
h1 {
  margin-top: 0px;
  margin-bottom: 1em;
  font-size: 2em;
  font-family: "Times New Roman";
}
h3.author {
  margin-top: 0px;
}
#background {
  background-image: url("/f/background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
#maincont {
  background-image: url("/f/background.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
.navbar {
  margin-bottom: 0px;
  border-radius: 0px;
}
#portalNav-row .navbar-inverse {
  background-color: #b09367;
  border-color: #b09367;
}
#portalNav-row .navbar-inverse a {
  color: white;
}
#portalNav-row .navbar-inverse ul.navbar-nav a {
  padding: 15px 10px;
}
#portalNav-row .navbar-inverse ul.navbar-nav li.open > a {
  background-color: #91754b;
}
#portalNav-row .navbar-inverse ul[class*=ul_level] {
  background-color: white;
}
#portalNav-row .navbar-inverse ul[class*=ul_level] a {
  color: #91754b;
  padding: 3px 20px !important;
}
#portalNav-row .navbar-inverse .navbar-collapse {
  border-color: #91754b;
}
#portalNav-row .navbar-inverse .navbar-toggle {
  border-color: #91754b;
}
#portalNav-row .navbar-inverse .navbar-toggle:hover {
  background-color: #91754b;
}
#portalNav-row span.search {
  float: right;
  width: 120px;
  padding: 8px 0;
  margin-right: -15px;
}
#portalNav-row span.search .ya-site-form__submit {
  display: none;
}
#portalNav-row span.search .ya-site-form__input-text {
  border-radius: 2px;
}
#portalNav-row span.search .ya-site-form__input-text::-webkit-input-placeholder {
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text:-moz-placeholder {
  /* FF 4-18 */
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text::-moz-placeholder {
  /* FF 19+ */
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text:-ms-input-placeholder {
  /* IE 10+ */
  color: #91754b;
}
#siteNav-row {
  font-size: 1.2em;
}
#siteNav-row .navbar-inverse {
  background-color: rgba(79, 44, 39, 0.8);
  border-color: #4f2c27;
}
#siteNav-row .navbar-inverse a {
  color: white;
}
#siteNav-row .navbar-inverse ul.navbar-nav {
  width: 100%;
}
#siteNav-row .navbar-inverse ul.navbar-nav li.lang {
  float: right;
}
#siteNav-row .navbar-inverse ul.navbar-nav li.lang a {
  width: 30px;
  text-align: center;
  background-color: white;
  border-radius: 50%;
  margin-top: 10px;
  padding: 5px;
  color: #4f2c27;
}
#siteNav-row .navbar-inverse ul.navbar-nav li.open > a {
  background-color: #713f38;
}
#siteNav-row .navbar-inverse ul[class*=ul_level] {
  background-color: white;
}
#siteNav-row .navbar-inverse ul[class*=ul_level] a {
  color: #60362f;
}
#siteNav-row .navbar-inverse .navbar-collapse {
  border-color: #713f38;
  border-top: none;
}
#siteNav-row .navbar-inverse .navbar-toggle {
  border-color: #713f38;
}
#siteNav-row .navbar-inverse .navbar-toggle:hover {
  background-color: #713f38;
}
#site-title-row h1 {
  text-transform: uppercase;
  font-family: Times New Roman;
  text-shadow: 2px 2px 3px black;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}
#site-title-row p {
  color: white;
  text-shadow: 2px 2px 3px black;
}
.content {
  opacity: 0.92;
  padding-top: 40px;
  background-color: white;
  min-height: 60vh;
}
.navbar-brand {
  padding: 2px 15px;
}
footer {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(79, 44, 39, 0.8);
  color: white;
  font-size: 0.8em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
footer a {
  color: white;
}
footer a:hover {
  color: white;
}
footer .address,
footer .author {
  text-align: right;
}
.after-footer {
  position: relative;
  margin-top: -80px;
  height: 15vh;
  background-image: url("/f/background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.sidenav,
.text-left {
  padding-bottom: 2em;
}
.numbers span:first-child {
  margin-left: 0.7em;
}
.number-list p.year {
  margin-bottom: 0px;
  margin-top: 10px;
  background-color: #c18981;
  color: white;
  padding-left: 0.7em;
}
.number-list .number {
  display: inline-block;
  padding: 0px 5px 0px 0px;
}
ul.lang-menu li.current a {
  padding-top: 0px;
  padding-bottom: 0px;
}
ul.lang-menu li.current:not(.open) a {
  color: white;
}
.logo {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
}
.redkolegia td {
  padding: 10px;
}
.redkolegia .photo {
  width: 75px;
  height: 75px;
  background-size: cover;
  border-radius: 37.5px;
}
.redkolegia .name {
  font-weight: bold;
}
.redkolegia .name span.surname {
  text-transform: uppercase;
}
.kinolenta {
  padding: 0.5em 0;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.5);
  background-image: url('//video.ivran.ru/f/kinolenta.gif');
  background-repeat: repeat-y;
  background-position: top center;
  background-size: contain;
}
.kinolenta img.thumb {
  display: block;
  border: 1px solid #b2a27e;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  margin: 0 auto 0.3em;
  width: 70%;
}
.kinolenta div.video-title {
  margin: 0 auto 1em;
  width: 70%;
}
.kinolenta div.video-title a {
  line-height: 100%;
  display: inline-block;
}
.switch-lang a {
  background-color: #4f2c27;
  color: white;
  border: 1px solid #4f2c27;
  border-radius: 5px;
  padding: 0.2em;
}
.switch-lang a:hover {
  color: white;
}
ul.breadcrumbs {
  padding: 0;
}
ul.breadcrumbs li {
  display: inline-block;
}
ul.breadcrumbs li + li::before {
  content: "\00BB";
  padding-right: 5px;
}
ul.issue-number-list,
ul.tag-list {
  padding: 0;
}
ul.issue-number-list li,
ul.tag-list li {
  display: inline-block;
  padding: 1px;
}
ul.issue-number-list li a,
ul.tag-list li a {
  padding: 2px 6px;
  background-color: #4f2c27;
  color: white;
}
table.article-annotation {
  border-collapse: separate;
  border-spacing: 0 1em;
}
table.article-annotation td.row-label {
  padding-right: 38px;
  color: #999;
}
table.article-annotation td.row-value a.author-link {
  font-weight: bold;
}
ul.tag-list li a {
  border-radius: 3px;
  background-color: #3d80ba;
}
.bookrow {
  padding-left: 7px;
  padding-right: 7px;
}
.bookcard {
  position: relative;
  z-index: 50;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bookcard .bookpic {
  width: 100%;
  border: 1px solid #555;
}
.bookcard .bookbg-parent {
  padding: 15px;
  background-color: #ffffff;
  line-height: 1;
}
.bookcard .bookbg {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #bbb;
  margin-bottom: 10px;
}
.bookcard .author {
  font-size: 10pt;
}
.bookcard .author.title-eng {
  margin-top: 10px;
  margin-bottom: 0;
}
.bookcard .title {
  font-size: 10pt;
}
.h-100 {
  height: 100% !important;
}
.magbanners .bookpic {
  border: 0;
}
.magbanners .bookbg {
  background-color: transparent;
}
.photo {
  width: 64px;
  height: 64px;
  background-size: cover;
  border-radius: 32px;
  float: left;
  margin-right: 24px;
  margin-bottom: 1em;
}
